export default [
    // 付款方式
    {
        text : "新訂單",
        value : "created"
    },
    {
        text : "未出貨",
        value : "apply"
    },
    {
        text : "退貨申請",
        value : "request_return"
    }
]