<template>
    <div v-if="isLogin" class="d-flex flex-column align-center justify-center">
        <v-container class="d-flex align-center justify-center py-1 text-xl"
                     style="background-color: #009775; color: white; padding: 0;border-radius: 0 0 4px 4px">
            今日待辦事項
        </v-container>
        <div style="width: 100%">
            <section>
                <v-container class="px-0 py-0 mb-4">
                    <liff-list-filter style="width: 100%" class="home-order-tab" :listKey="listKey"/>
                </v-container>
                <v-container class="py-0 mb-4">
                    <liff-list-search class="search-full-width" :listKey="listKey"/>
                </v-container>
                <v-container v-if="listData.length === 0" class="py-0 mb-4">
                    <p>無相關資料</p>
                </v-container>
                <v-container class="mb-8">
                    <list-item
                            v-for="(data, index) in listData"
                            :key="index"
                            :listKey="listKey"
                            :data="data"
                    />
                </v-container>
            </section>
        </div>
    </div>
</template>

<script lang="babel" type="text/babel">
import listMixin from "@/components/list/mixins/listMixin.js"
import listConfig from "./homeOrderList"
import homeOrderTab from '@/modules/base/config/homeOrderTab.ts';
import liffListFilter from "components/liffList/listFilter/liffListFilter.vue";
import liffListSearch from "components/liffList/listSearch/liffListSearch.vue";

export default {
    mixins: [listMixin],
    components: {
        liffListSearch,
        liffListFilter,
        listItem: () => import('@/modules/base/components/home/orderCardItem.vue'),
    },
    data: () => ({
        listKey: 'liff-provider-order-list',
        meta: {},
        apiData: '',
        homeOrderTab: homeOrderTab,
    }),
    computed: {
        isLogin() {
            return this.$store.getters['token/isLogin']
        },
        siteName() {
            return this.$store.getters['base/siteName']
        },
        providerId() {
            return this.$store.getters[`member/providerId`]
        },
        listFilter() {
            return this.$store.getters[`list/${this.listKey}/filter`]
        },
        listData() {
            return this.$store.getters[`list/${this.listKey}/data`];
        },
        status() {
            if (!this.listFilter) return null
            return this.listFilter.status
        },
        title() {
            if (!this.status) return ''
            return this.$t(`provider.status.${this.status}`)
        },
    },
    mounted () {
        this.$root.gaLogEvent('供應商_待辦事項頁')
    },
    methods: {
        async beforeIndex() {
            await Promise.all([])
        },
        async indexApi(params) {
            return await this.$api.collection.providerApi.getOrders(this.providerId, params)
        },
        getListConfig() {
            return listConfig
        },
    },

}
</script>

<style lang="sass" type="text/sass">
.home-order-tab
  p
    display: none

  .v-slide-group__prev, .v-slide-group__next, .v-tabs-slider-wrapper
    display: none !important

  .v-tab
    min-width: 105px
    max-width: initial !important
    background: #F6F6F6 !important
    color: #000000 !important
    border-left: 1px solid #ffffff
    border-right: 1px solid #ffffff

    &--active
      background: #009775 !important
      border: 1px solid #009775
      color: #ffffff !important


  .v-slide-group__wrapper
    background: #F6F6F6

.search-full-width
  width: 100%
  padding: 0 1rem
</style>
