import Vue from 'vue'
import { LiffListConfigInterface, TableDataType } from '@/components/list/types/list'
import  homeOrderTab  from '@/modules/base/config/homeOrderTab.ts';
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string): LiffListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      searchFilterOrder: 1,
      pageTitle: 'module.order',
      search: 'order.search_keyword',
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      liffFilter: {
        // created_at: {
        //   label: 'data.created_at',
        //   type: 'date',
        // },
        status: {
          type: "tab",
          // create: () => {
          //   // @ts-ignore
          //   const searchKeyword = vm.searchKeyword
          //   return !searchKeyword
          // },
          options: () => {
            return homeOrderTab
          },
        },
      },
      listItem: () => import('@/modules/provider/views/order/orderCardItem.vue'),

      // listItem: () => import('@/modules/base/components/home/orderCardItem.vue'),
      useRow: false,
    }

  }
}

export default new listConfig()
